import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SideBySide from "../components/SideBySide"

const sideBySideFirstRow = [
  `Air Filters(s): either replace with a pleated disposable filter, or rinse/clean your reusable`,
  `Chemically clean and inspect evaporator coil if accessible`,
  `Chemically clean and inspect condenser coil`,
  `Inspect blower wheel`,
  `Inspect high voltage connections`,
  `Tighten high voltage connections`,
  `Inspect low voltage connections`,
  `Inspect line contactor contacts for pitting and wear`,
  `Check thermostat calibration`,
  `Clean controls compartment`,
  `Check run capacitors`,
  `Check condenser fan blade`,
  `Check condenser fan motor bearings`,
  `Oil motors if applicable`,
  `Check and record operating voltage`,
  `Check and record compressor amps`,
  `Check and record condenser fan motor amps`,
  `Check and record blower motor amps`,
  `Check blower motor bearings`,
  `Check and record refrigerant pressures`,
  `Check and record temperature drop`,
  `Clear condensate drain`,
  `Observe overall operation`,
]

const sideBySideSecondRow = [
  `Air Filters(s): either replace with a pleated disposable filter, or rinse/clean your reusable`,
  `Check combustion`,
  `Inspect heat exchangers`,
  `Inspect and clean burners`,
  `Check and adjust manifold gas pressure if necessary`,
  `Adjust burners if necessary`,
  `Inspect ignition assembly`,
  `Ignition System test for safe and proper operation`,
  `Check high limit controls`,
  `Inspect blower assembly`,
  `Check blower motor bearings`,
  `Oil blower motor if applicable`,
  `Check and record blower motor amps`,
  `Inspect low voltage connections`,
  `Inspect high voltage connections`,
  `Inspect pilot assembly (if applicable)`,
  `Clean combustion compartment`,
  `Check combustion motor`,
  `Check and record combustion motor amps`,
  `Check thermostat calibration`,
  `Check and record temperature rise`,
  `Observe overall operation`,
  `For Heat Pumps: Check and record heat strips amp draws plus check fusible links`,
]

export default function Maintenance({ data }) {
  return (
    <Layout>
      <Seo title="Maintenance" />
      <h1 className="my-4 pt-4 pb-2 text-blue font-extrabold text-3xl sm:text-4xl text-center">
        Alpine Maintenance Program (AMP) Membership
      </h1>
      <p className="block max-w-3xl px-4 mx-auto text-xl text-center text-gray-500">
        Twice a year we will come out and thoroughly inspect your unit for any
        visual or measurable deficiencies which we will bring to your attention.
      </p>

      <SideBySide
        image={data.row1Image}
        items={sideBySideFirstRow}
        header="Air Conditioner Clean: Cooling Season"
        imgRight
        subheader="Trained Service Technicians will perform a 60-90 minute precision tune-up and professional cleaning which includes the following 20+ operations"
      />
      <SideBySide
        image={data.row2Image}
        items={sideBySideSecondRow}
        header="Furnace Clean: Heating Season"
        subheader="Trained Service Technicians will perform a 45-60 minute precision tune-up and professional cleaning which includes the following 20+ operations"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query MaintenancePageQuery {
    row1Image: file(relativePath: { eq: "ac-roof.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    row2Image: file(relativePath: { eq: "maintenance.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
